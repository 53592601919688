import axios from 'axios'
import config from '@/config'
import { useAppStore } from '@/storage/app'
import { storeToRefs } from 'pinia'
import { useFingerprintStore } from '@/storage/fingerprint'
import Log from '@/utilities/Log'
import ReportableException from '@/exceptions/ReportableException'
import { UserType } from '@/services/api/customer'

export type Site = {
  street: string
  customer: Customer
  id: number
  locationCentroid: [number, number]
  name: string
  siteDocuments: VerifiedDocument[]
  siteHazards: SiteHazard[]
  uuid: string
}
export type Customer = {
  connection: string
  email: string
  hasAccreditation: boolean
  hasCheckList: boolean
  hasGPS: boolean
  hasInduction: boolean
  hasNotice: boolean
  hasSpotChecks: boolean
  hasUnregisteredContractor: boolean
  hasVisitorArrivalNotification: boolean
  hasWorkOrder: boolean
  id: number
  name: string
  needsPermitPerService: boolean
  phone: string
  postcode: string
  requiresSupervisorSignature: boolean
  state: string
  street: string
  town: string
  customerDocuments?: VerifiedDocument[]
  supervisorChecklist?: string[]
}
export type VerifiedDocument = {
  documentName: string
  id: number
  documentEndpoint?: string
}
export type SiteHazard = {
  description: string
  id: number
}
export type AuthorisedContractorCompany = {
  id: number
  name: string
  userTypes: UserType[]
}
export let controller: AbortController = new AbortController()

export type DocumentMeta = {
  createdAt: string
  images: string[]
  mime: string
  name: string
  origin: string
  total: number
  uuid: string
}

export const getSites = async (
  userId: string,
  userLocation: Position,
  page: string,
  search: string | null = null,
  brandIds: number[] = [],
): Promise<Site[] | undefined> => {
  controller = new AbortController()
  try {
    const response = await axios.get('/api/sites', {
      params: {
        brandIds,
        canBypass: 1,
        page,
        perPage: config.pagination.sites.perPage,
        search,
        userId,
        userLocation,
      },
      signal: controller.signal,
    })

    if (response) {
      return response?.data?.data
    }
    return []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message !== 'canceled') {
      return []
    }
  }
}

export const getNearbySites = async (
  userId: string,
  userLocation: Position,
  radius: number | null = null,
  brandIds: number[] = [],
): Promise<Site[] | undefined> => {
  const response = await axios.get('/api/sites', {
    params: {
      brandIds,
      radius,
      userId,
      userLocation,
    },
  })

  if (response) {
    return response?.data?.data
  }
  return []
}

export const getSiteDocumentMeta = async (
  siteId: number,
  documentId: number,
): Promise<DocumentMeta> => {
  const { token } = storeToRefs(useAppStore())
  const { getOs } = storeToRefs(useFingerprintStore())

  const url = `/api/documents/${documentId}/${siteId}`
  const response: DocumentMeta = (await axios.get(url)).data.data

  response.images = response.images.map((url) => url + `?token=${token.value}`)
  response.origin += `?token=${token.value}&os=${getOs.value?.toLowerCase()}`

  if (response.images.length === 0) {
    Log.notify(
      new ReportableException(
        'Image content missing',
        {
          documentId: documentId,
          siteId: siteId,
        },
        ['SiteDocumentMissing'],
      ),
      'SiteDocumentMissing',
    )
  }

  return response
}

export async function getSite(siteUuid: string): Promise<Site> {
  const res = await axios.get('api/clients/sites/' + siteUuid)

  return res.data.data
}
