import axios from 'axios'
import { addOrAppendAxiosHeader } from '@/services/axios'
import { storeToRefs } from 'pinia'
import { useCustomerStore } from '@/storage/customer'

const clientDatabaseInterceptor = (): void => {
  axios.interceptors.request.use(
    function (config) {
      const { connection } = storeToRefs(useCustomerStore())

      if (connection.value === null) {
        return Promise.resolve(config)
      }

      config.headers = addOrAppendAxiosHeader(
        'x-client-connection',
        `${connection.value}`,
        config.headers,
      )

      return Promise.resolve(config)
    },
    function (error) {
      return Promise.reject(error)
    },
  )
}

export default clientDatabaseInterceptor
