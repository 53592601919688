<template>
  <div>
    <SiteBanner :site-address="siteAddress" :site-name="siteName" />
    <WorkOrderDetails v-if="hasSelectedWorkOrder" class="mt-4" />
    <section class="mx-auto mb-6 mt-4 w-11/12 rounded-xl border p-5 shadow">
      <h1 class="text-center text-lg">Select service being completed</h1>
      <div v-if="servicesAtSite?.length === 0">
        No active services at this site
      </div>
      <ul v-if="servicesAtSite?.length !== 0">
        <li
          v-for="service in servicesAtSite"
          :key="servicesAtSite.indexOf(service)"
          class="relative mt-2 rounded border border-verified-blue p-3 text-verified-blue"
          @click="selectedActiveService(service)"
        >
          <p class="w-5/6">{{ service?.name || service.service.name }}</p>
          <span
            v-if="siteServiceIsCompleted(service.siteServiceId)"
            class="absolute right-2 top-3.5"
          >
            <CheckCircleIcon class="completed-service-icon h-5 w-5" />
          </span>
          <span v-else class="absolute right-2 top-3.5">
            <ChevronRightIcon class="select-service-icon h-5 w-5" />
          </span>
        </li>
      </ul>
      <div
        v-if="addServiceOptions.length !== 0"
        class="add-service"
        :class="[canSkipServiceSelection ? 'mt-4' : 'mb-8 mt-6']"
      >
        <BaseSelector
          :options="addServiceOptions"
          placeholder="Add a service"
          @option-changed="selectedOtherService"
        ></BaseSelector>
      </div>
    </section>
    <section
      v-if="canSkipServiceSelection"
      class="mx-auto my-12 w-11/12 rounded-xl border py-1 shadow"
    >
      <button
        id="service-selection-ready-to-checkout-button"
        type="button"
        class="flex w-full items-center justify-center space-x-4 rounded-lg p-3"
        @click.once="routeToCheckout"
      >
        <span>Ready to Checkout</span>
        <span class="text-red-600">
          <ArrowRightStartOnRectangleIcon class="h-7 w-7" />
        </span>
      </button>
    </section>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import BaseSelector from '@/components/BaseSelector.vue'
  import {
    onBeforeRouteLeave,
    RouteLocationNormalized,
    useRouter,
  } from 'vue-router'
  import {
    getAllServices,
    getSiteServices,
    Service,
    SiteServiceType,
  } from '@/services/api/services'
  import { SelectOption } from '@/config'
  import {
    ArrowRightStartOnRectangleIcon,
    ChevronRightIcon,
  } from '@heroicons/vue/24/outline'
  import { CheckCircleIcon } from '@heroicons/vue/24/solid'
  import { CompletableServices, useServicesStore } from '@/storage/services'
  import SiteBanner from '@/components/SiteBanner.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import WorkOrderDetails from '@/components/WorkOrderDetails.vue'
  import { WorkOrder } from '@/services/api/workOrder'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useSiteStore } from '@/storage/site'
  import { useWorkOrderStore } from '@/storage/workOrder'
  import { useUserStore } from '@/storage/user'

  const {
    setServices,
    setSelectedService,
    setSelectedServiceType,
    setSiteServices,
  } = useServicesStore()
  const { allServices: services } = storeToRefs(useServicesStore())
  const {
    uuid: siteUuid,
    name: siteName,
    address: siteAddress,
    isSiteStoreDefault,
  } = storeToRefs(useSiteStore())
  const {
    selectedContractorCompanyId,
    isSelectedContractorCompanyIdDefault,
    id: userId,
    contractorCompanies,
  } = storeToRefs(useUserStore())
  const { id: customerId, isCustomerStoreDefault } =
    storeToRefs(useCustomerStore())
  const { fingerprint } = storeToRefs(useFingerprintStore())

  const router = useRouter()

  const servicesAtSite = ref<SiteServiceType[]>([])
  const allContractorServices = ref<Service[]>([])
  const addServiceOptions = ref<SelectOption[]>([])
  const { hasSelectedWorkOrder, selectedWorkOrder } =
    storeToRefs(useWorkOrderStore())
  const canSkipServiceSelection = computed(() => {
    const selected = contractorCompanies.value.find((company) => {
      return company?.id === selectedContractorCompanyId.value
    })

    const contractorVendor = selected?.contractorVendors?.find(
      (vendor) => vendor.verifiedCustomerId == customerId.value,
    )

    return contractorVendor?.canSkipServices || false
  })

  onBeforeRouteLeave((to: RouteLocationNormalized) => {
    if (to.name === 'contractorCompanySelection') {
      return false
    }
  })

  if (
    isSiteStoreDefault.value ||
    isSelectedContractorCompanyIdDefault.value ||
    isCustomerStoreDefault.value
  ) {
    throw new InvalidStoreException(
      {
        customerId: customerId.value,
        fingerprint: fingerprint.value,
        selectedContractorCompany: selectedContractorCompanyId.value,

        siteUuid: siteUuid.value,
        userId: userId.value,
      },
      ['InvalidStore', 'FetchServices'],
    )
  }

  await fetchSiteServices(siteUuid.value, selectedContractorCompanyId.value)
  await fetchAllServices(selectedContractorCompanyId.value, customerId.value)
  if (hasSelectedWorkOrder.value) {
    filterWorkOrderServices()
  }

  excludeSiteServices(servicesAtSite.value, allContractorServices.value)
  shouldSkipThisPage()

  const siteServiceIsCompleted = (siteServiceId: number): boolean => {
    return (
      services.value.find((service) => {
        if ('service' in service) {
          return service.siteServiceId === siteServiceId
        }
        return false
      })?.completed || false
    )
  }

  function addCompletedPropertyAndSaveInStore(
    services: Service[] | SiteServiceType[],
  ) {
    const completableServices = services.map(
      (service: Service | SiteServiceType) => {
        return { ...service, completed: false }
      },
    )
    setServices((completableServices as CompletableServices) || [])
  }

  async function fetchSiteServices(
    siteUuid: string,
    contractorCompanyId: number,
  ): Promise<void> {
    try {
      servicesAtSite.value = await getSiteServices(
        siteUuid,
        contractorCompanyId,
      )
      addCompletedPropertyAndSaveInStore(servicesAtSite.value)
      setSiteServices(servicesAtSite.value)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // console.debug(e)
      // prevent Capture from showing the error to users if there is no active service
      // since non-active services might be available
    }
  }

  async function fetchAllServices(
    contractorCompanyId: number,
    customerId: number | null,
  ): Promise<void> {
    const response = await getAllServices(contractorCompanyId, customerId)
    allContractorServices.value = response as Service[]
  }

  function filterWorkOrderServices() {
    const workOrderServiceIds = (selectedWorkOrder.value as WorkOrder)
      .serviceIds

    if (workOrderServiceIds.length === 0) {
      return
    }

    const filteredSiteServices = servicesAtSite.value.filter((service) =>
      workOrderServiceIds.includes(service.service.parentServiceId),
    )

    const filteredContractorServices = allContractorServices.value.filter(
      (service) => workOrderServiceIds.includes(service.parentServiceId),
    )

    if (
      filteredContractorServices.length === 0 &&
      filteredSiteServices.length === 0
    ) {
      return
    }

    servicesAtSite.value = filteredSiteServices
    allContractorServices.value = filteredContractorServices
  }

  function excludeSiteServices(
    servicesAtSite: SiteServiceType[],
    allContractorServices: Service[],
  ): void {
    const filteredContractorServices =
      servicesAtSite.length === 0
        ? allContractorServices
        : allContractorServices?.filter((service) => {
            return servicesAtSite.every((serviceAtSite) => {
              return (
                serviceAtSite?.service.clientServiceId !==
                service?.clientServiceId
              )
            })
          })

    addServiceOptions.value =
      filteredContractorServices.map((service) => {
        return {
          id: service.clientServiceId,
          name: service.name,
        }
      }) || []

    addCompletedPropertyAndSaveInStore(filteredContractorServices)
  }

  async function storeSelectedServiceAndRouteToFreqPage(
    service: Service | SiteServiceType,
    serviceType: string,
  ): Promise<void> {
    setSelectedService(service)
    setSelectedServiceType(serviceType)
    await router.replace({
      name: 'serviceFrequency',
      params: { serviceType: serviceType },
    })
  }

  function selectedActiveService(service: SiteServiceType) {
    storeSelectedServiceAndRouteToFreqPage(service, 'activeService')
  }

  function selectedOtherService(serviceId: number | string) {
    const service = services.value.find((service) => {
      if ('service' in service) {
        return service.siteServiceId === serviceId
      }
      return service.clientServiceId === serviceId
    }) as Service
    storeSelectedServiceAndRouteToFreqPage(service, 'otherService')
  }

  function shouldSkipThisPage() {
    if (
      servicesAtSite.value.length === 1 &&
      addServiceOptions.value.length === 0
    ) {
      selectedActiveService(servicesAtSite.value[0])
    }

    if (
      servicesAtSite.value.length === 0 &&
      addServiceOptions.value.length === 1
    ) {
      selectedOtherService(addServiceOptions.value[0].id)
    }
  }

  function routeToCheckout() {
    router.replace({ name: 'checkout' })
  }
</script>
