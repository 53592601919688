<template>
  <section class="m-4">
    <SiteBanner :site-address="siteAddress" :site-name="siteName" />
    <p class="mt-8">
      Are you required to complete one of the following routines?
    </p>
    <ul class="my-8">
      <li
        v-for="service in serviceSiblings"
        :key="service.clientServiceId"
        class="relative mt-2 rounded border border-blue-500 p-3 text-blue-500"
        @click="selectedActiveService(service)"
      >
        <p class="w-5/6">{{ service?.name }}</p>
        <span
          v-if="serviceIsCompleted(service.clientServiceId)"
          class="absolute right-2 top-3.5"
        >
          <CheckCircleIcon class="completed-service-icon h-5 w-5" />
        </span>
        <span v-else class="absolute right-2 top-3.5">
          <ChevronRightIcon class="select-service-icon h-5 w-5" />
        </span>
      </li>
    </ul>
    <p class="mb-4">Completed all services?</p>
    <PrimaryButton action="Continue" @click="routeToConfirmation" />
  </section>
</template>

<script lang="ts">
  import { defineComponent, onBeforeUnmount } from 'vue'
  import SiteBanner from '@/components/SiteBanner.vue'
  import { Service } from '@/services/api/services'
  import router from '@/router'
  import { ChevronRightIcon } from '@heroicons/vue/24/outline'
  import { CheckCircleIcon } from '@heroicons/vue/24/solid'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import { storeToRefs } from 'pinia'
  import { useFrequenciesStore } from '@/storage/frequencies'
  import { useServicesStore } from '@/storage/services'
  import { useSiteStore } from '@/storage/site'

  export default defineComponent({
    name: 'ServiceSiblingSelection',
    components: {
      CheckCircleIcon,
      ChevronRightIcon,
      PrimaryButton,
      SiteBanner,
    },

    async setup() {
      const { address: siteAddress, name: siteName } =
        storeToRefs(useSiteStore())
      const { resetServiceSiblings, setSelectedService } = useServicesStore()
      const { serviceSiblings } = storeToRefs(useServicesStore())
      const { previouslyCompleted, selectedFrequency } = storeToRefs(
        useFrequenciesStore(),
      )

      onBeforeUnmount(() => {
        resetServiceSiblings()
      })

      const serviceIsCompleted = (serviceId: number): boolean => {
        const serviceToCheck = previouslyCompleted.value.find((service) => {
          return service.clientServiceId === serviceId
        })
        return (
          selectedFrequency.value.every((freq) =>
            serviceToCheck?.frequencyId.includes(freq),
          ) || false
        )
      }

      const allServicesCompleted = serviceSiblings.value.every((service) =>
        serviceIsCompleted(service.clientServiceId),
      )

      if (allServicesCompleted || serviceSiblings.value.length === 0) {
        await routeToConfirmation()
      }

      function selectedActiveService(s: Service) {
        storeSelectedServiceAndRouteToServiceForm(s)
      }

      async function storeSelectedServiceAndRouteToServiceForm(
        service: Service,
      ): Promise<void> {
        setSelectedService(service)
        await router.replace({
          name: 'serviceForm',
        })
      }

      async function routeToConfirmation(): Promise<void> {
        await router.replace({
          name: 'serviceCheckout',
        })
      }

      return {
        routeToConfirmation,
        selectedActiveService,
        serviceIsCompleted,
        serviceSiblings,
        siteAddress,
        siteName,
      }
    },
  })
</script>
