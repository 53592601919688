import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import { ContractorCompany } from '@/services/api/user'
import { UserType } from '@/services/api/customer'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'

dayjs.extend(utc)
dayjs.extend(timezone)

export const useUserStore = defineStore(
  'user',
  () => {
    /** state */
    const contractorCompanies = ref<ContractorCompany[]>([])
    const contractorEmployeeId = ref(0)
    const employeePhoto = ref<string | null>(null)
    const firstName = ref('there')
    const id = ref(0)
    const identityIsValid = ref(false)
    const lastname = ref('')
    const name = ref('')
    const phone = ref('')
    const selectedContractorCompanyId = ref(0)
    const timezone = ref('')
    const userType = ref<UserType>({ id: 0, name: '' })
    const checkedInTime = ref<string | null>(null)

    /** getters */
    const selectedContractorCompanyName: ComputedRef<string> = computed(
      () =>
        contractorCompanies.value.find(
          (contractor) => contractor.id === selectedContractorCompanyId.value,
        )?.name ?? '',
    )

    const fullName: ComputedRef<string> = computed(
      () => `${firstName.value} ${lastname.value}`,
    )

    const selectedContractorCompanyUserType: ComputedRef<UserType> =
      // Assume there is only one user type for registered contractors
      computed(
        () =>
          contractorCompanies.value.find(
            (contractor) => contractor.id === selectedContractorCompanyId.value,
          )?.userTypes[0] ?? { id: 0, name: '' },
      )

    const hasSelectedContractorCompany: ComputedRef<boolean> = computed(
      () => selectedContractorCompanyId.value > 0,
    )

    const isLoggedIn: ComputedRef<boolean> = computed(() => id.value > 0)

    const isContractor: ComputedRef<boolean> = computed(
      () => contractorCompanies.value.length > 0,
    )

    const hasUserType: ComputedRef<boolean> = computed(
      () => userType.value.id > 0,
    )

    const isUserStoreDefault: ComputedRef<boolean> = computed(
      () => id.value === 0,
    )

    const isUserTypeDefault: ComputedRef<boolean> = computed(
      () => userType.value.id === 0 && userType.value.name === '',
    )
    const isSelectedContractorCompanyIdDefault: ComputedRef<boolean> = computed(
      () => selectedContractorCompanyId.value === 0,
    )

    const activeVendorBrandIds = computed(() => {
      const activeVendors = contractorCompanies.value.map(
        (contractorCompany) => {
          if (!contractorCompany.contractorVendors) {
            return []
          }
          return contractorCompany.contractorVendors?.filter(
            (contractorVendor) => {
              return contractorVendor.active
            },
          )
        },
      )

      return Array.from(
        new Set(
          activeVendors.flatMap((contractorVendor) =>
            contractorVendor.map((vendor) => vendor.verifiedCustomerId),
          ),
        ),
      )
    })

    function activeVendorsForBrand(brandId: number): ContractorCompany[] {
      return contractorCompanies.value.filter((contractorCompany) => {
        if (!contractorCompany.contractorVendors) {
          return false
        }
        return contractorCompany.contractorVendors.some(
          (contractorVendor) =>
            contractorVendor.verifiedCustomerId === brandId &&
            contractorVendor.active,
        )
      })
    }

    /** actions */
    function setUserAuthenticationResult(authentication: boolean): void {
      identityIsValid.value = authentication
    }

    function setSelectedContractorCompanyId(id: number) {
      selectedContractorCompanyId.value = id
    }

    function setTimezone(): void {
      timezone.value = dayjs.tz.guess()
    }

    function setUserFirstName(value: string) {
      firstName.value = value
    }

    function setUserLastName(value: string) {
      lastname.value = value
    }

    function setUserId(value: number) {
      id.value = value
    }

    function setUserPhoneNumber(value: string) {
      phone.value = value
    }

    function setUserContractorEmployeeId(value: number) {
      contractorEmployeeId.value = value
    }

    function setUserType(value: UserType) {
      userType.value = value
    }

    function setEmployeePhoto(value: string | null): void {
      employeePhoto.value = value
    }

    function setCheckedInTime(value: string): void {
      checkedInTime.value = value
    }

    function setUserContractorCompanies(
      newContractorCompanies: ContractorCompany[],
    ) {
      if (
        contractorCompanies.value[0] &&
        contractorCompanies.value[0].id === 0
      ) {
        contractorCompanies.value.shift()
      }

      newContractorCompanies.forEach((company: ContractorCompany) => {
        const companyExists = contractorCompanies.value.find(
          (contractorCompany) =>
            contractorCompany.id.toString() === company.id.toString(),
        )
        if (!companyExists) {
          contractorCompanies.value.push(company)
        }
      })
    }

    function resetUser() {
      contractorCompanies.value = []
      contractorEmployeeId.value = 0
      employeePhoto.value = null
      firstName.value = ''
      id.value = 0
      identityIsValid.value = false
      lastname.value = ''
      name.value = ''
      phone.value = ''
      selectedContractorCompanyId.value = 0
      timezone.value = ''
      userType.value = { id: 0, name: '' }
      checkedInTime.value = null
    }

    return {
      activeVendorBrandIds,
      activeVendorsForBrand,
      checkedInTime,
      contractorCompanies,
      contractorEmployeeId,
      employeePhoto,
      firstName,
      fullName,
      hasSelectedContractorCompany,
      hasUserType,
      id,
      identityIsValid,
      isContractor,
      isLoggedIn,
      isSelectedContractorCompanyIdDefault,
      isUserStoreDefault,
      isUserTypeDefault,
      lastname,
      name,
      phone,
      resetUser,
      selectedContractorCompanyId,
      selectedContractorCompanyName,
      selectedContractorCompanyUserType,
      setCheckedInTime,
      setEmployeePhoto,
      setSelectedContractorCompanyId,
      setTimezone,
      setUserAuthenticationResult,
      setUserContractorCompanies,
      setUserContractorEmployeeId,
      setUserFirstName,
      setUserId,
      setUserLastName,
      setUserPhoneNumber,
      setUserType,
      timezone,
      userType,
    }
  },
  {
    persist: true,
  },
)
