import { defineStore } from 'pinia'
import { ref } from 'vue'
import {
  Answer,
  ImageAnswer,
  Serializable,
  TrolleyCollection,
} from '@verifiedit/form-generator'
import dayjs from 'dayjs'

type FormAnswer =
  | string
  | number
  | (string | number | TrolleyCollection | ImageAnswer)[]

export const useFormAnswersStore = defineStore(
  'formAnswers',
  () => {
    /** state */
    const clientServiceId = ref<number | null>(null)
    const siteServiceId = ref<number | null>(null)
    const frequencies = ref<number[]>([])
    const answers = ref<{ [key: string]: FormAnswer }>({})
    const jobStart = ref<string | null>(null)

    /** getters */

    /** actions */
    function addFormAnswer(answer: FormAnswer, identifier: string): void {
      let valueToAdd: FormAnswer
      if (
        typeof answer === 'object' &&
        answer.length > 0 &&
        answer.every((a) => a !== null) &&
        (<Serializable>answer[0]).serialize !== undefined
      ) {
        valueToAdd = answer.map((value) => (<Serializable>value).serialize())
      } else {
        valueToAdd = answer
      }
      answers.value[identifier] = valueToAdd
    }

    function getFormAnswers(
      currentClientServiceId: number | null,
      currentSiteServiceId: number | null,
      currentFrequencies: number[],
    ): Map<string, Answer> | null {
      if (
        hasAnswersForService(
          currentClientServiceId,
          currentSiteServiceId,
          currentFrequencies,
        )
      ) {
        return new Map(Object.entries(answers.value))
      }
      return null
    }

    function hasAnswersForService(
      currentClientServiceId: number | null,
      currentSiteServiceId: number | null,
      currentFrequencies: number[],
    ): boolean {
      if (currentClientServiceId === null || currentFrequencies === null) {
        return false
      }
      return (
        clientServiceId.value === currentClientServiceId &&
        siteServiceId.value === currentSiteServiceId &&
        frequencies.value.every((frequency) =>
          currentFrequencies.includes(frequency),
        )
      )
    }

    function setService(
      clientService: number,
      siteService: number | null,
    ): void {
      clientServiceId.value = clientService
      siteServiceId.value = siteService
    }

    function setFrequencies(value: number[]): void {
      frequencies.value = value
    }

    function clearAnswers(): void {
      answers.value = {}
    }

    function setJobStart(): void {
      if (jobStart.value === null) {
        jobStart.value = dayjs().format('DD-MM-YYYY h:mm:ssA')
      }
    }

    function resetFormAnswers() {
      answers.value = {}
      frequencies.value = []
      jobStart.value = null
      clientServiceId.value = null
      siteServiceId.value = null
    }

    return {
      addFormAnswer,
      answers,
      clearAnswers,
      clientServiceId,
      frequencies,
      getFormAnswers,
      jobStart,
      resetFormAnswers,
      setFrequencies,
      setJobStart,
      setService,
      siteServiceId,
    }
  },
  { persist: true },
)
