import { defineStore } from 'pinia'
import { computed, ComputedRef, Ref, ref } from 'vue'
import { Service, SiteServiceType } from '@/services/api/services'

export type CompletableService = Service & {
  completed: boolean
}
export type CompletableSiteService = SiteServiceType & {
  completed: boolean
}
export type CompletableServices =
  | CompletableService[]
  | CompletableSiteService[]

export const useServicesStore = defineStore(
  'services',
  () => {
    /** state */
    const allServices = ref([] as CompletableServices)
    const servicesAtSite = ref([] as SiteServiceType[])
    const selectedService: Ref<Service | SiteServiceType | null> = ref(null)
    const selectedServiceType = ref('')
    const serviceSiblings = ref<Service[]>([])

    /** getters */
    const hasSelectedService: ComputedRef<boolean> = computed(
      () => selectedService.value !== null,
    )

    const isSelectedServiceDefault: ComputedRef<boolean> = computed(
      () => selectedService.value === null,
    )

    const selectedServiceId = computed(() => {
      if (!selectedService.value) {
        return null
      }

      if ('service' in selectedService.value) {
        return selectedService.value.service.parentServiceId
      }

      return selectedService.value.parentServiceId
    })

    const selectedSubServiceId = computed(() => {
      if (!selectedService.value) {
        return null
      }

      if ('lookupSubService' in selectedService.value) {
        return selectedService.value.lookupSubService.subServiceId
      }

      return 0
    })

    const selectedServiceSiteServiceId = computed(() => {
      if (!selectedService.value) {
        return null
      }

      if ('service' in selectedService.value) {
        return selectedService.value.siteServiceId
      }

      return null
    })

    const selectedServiceClientServiceId = computed(() => {
      if (!selectedService.value) {
        return null
      }

      if ('service' in selectedService.value) {
        return selectedService.value.service.clientServiceId
      }

      return selectedService.value.clientServiceId
    })

    const hasReactiveMaintenance = computed<boolean>(() => {
      if (!selectedService.value) {
        return false
      }

      if ('service' in selectedService.value) {
        return selectedService.value.service.canRepair
      }

      return selectedService.value.canRepair
    })

    /** actions */
    function setServiceSiblings(value: Service[]): void {
      serviceSiblings.value = value
    }

    function setSelectedService(value: Service | SiteServiceType): void {
      selectedService.value = value
    }

    function setSelectedServiceType(value: string): void {
      selectedServiceType.value = value
    }

    function setServices(services: CompletableServices) {
      services.forEach((service) => {
        const identical = allServices.value.filter(
          (serviceInStore: Service | SiteServiceType) => {
            if ('service' in serviceInStore) {
              return serviceInStore.siteServiceId === service.siteServiceId
            }
            return serviceInStore.clientServiceId === service.clientServiceId
          },
        )
        if (identical.length === 0) {
          allServices.value.push(service)
        }
      })
    }

    const setSiteServices = (services: SiteServiceType[]) => {
      services.forEach((service) => {
        const identical = servicesAtSite.value.filter(
          (serviceInStore) =>
            serviceInStore.siteServiceId === service.siteServiceId,
        )

        if (identical.length === 0) {
          servicesAtSite.value.push(service)
        }
      })
    }

    function setCompleted(
      clientServiceId: number,
      siteServiceId: number | null,
    ) {
      const service = allServices.value.find((service) => {
        if ('service' in service) {
          return service.siteServiceId === siteServiceId
        }

        return service.clientServiceId === clientServiceId
      })
      if (service !== undefined) {
        service.completed = true
      }
    }

    function resetServiceSiblings(): void {
      serviceSiblings.value = []
    }

    function resetServices() {
      allServices.value = []
      selectedService.value = null
      selectedServiceType.value = ''
      serviceSiblings.value = []
      servicesAtSite.value = []
    }

    return {
      allServices,
      hasReactiveMaintenance,
      hasSelectedService,
      isSelectedServiceDefault,
      resetServiceSiblings,
      resetServices,
      selectedService,
      selectedServiceClientServiceId,
      selectedServiceId,
      selectedServiceSiteServiceId,
      selectedServiceType,
      selectedSubServiceId,
      serviceSiblings,
      servicesAtSite,
      setCompleted,
      setSelectedService,
      setSelectedServiceType,
      setServiceSiblings,
      setServices,
      setSiteServices,
    }
  },
  {
    persist: true,
  },
)
