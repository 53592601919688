import { defineStore } from 'pinia'
import { computed, ComputedRef, ref } from 'vue'
import {
  AuthorisedContractorCompany,
  Site,
  SiteHazard,
  VerifiedDocument,
} from '@/services/api/site'

export const useSiteStore = defineStore(
  'site',
  () => {
    /** state */
    const address = ref('')
    const authorisedContractorCompanies = ref<AuthorisedContractorCompany[]>([])
    const exists = ref(false)
    const name = ref('')
    const id = ref(0)
    const uuid = ref('')
    const latitude = ref(0)
    const longitude = ref(0)
    const selectionConfirmed = ref(false)
    const hasSameSiteSetInSession = ref(false)
    const siteDocuments = ref([] as VerifiedDocument[])
    const siteHazards = ref([] as SiteHazard[])
    const seenHazards = ref(false)

    /** getters */
    const isSiteStoreDefault: ComputedRef<boolean> = computed(
      () => id.value === 0,
    )

    const canContinueAsVisitor: ComputedRef<boolean> = computed(() => {
      if (authorisedContractorCompanies.value.length === 0) {
        return true
      }

      return authorisedContractorCompanies.value.some((contractor) =>
        contractor.userTypes.some((userType) => userType.name !== 'Cleaner'),
      )
    })

    /** actions */
    function setAuthorisedContractorCompanies(
      value: AuthorisedContractorCompany[],
    ) {
      authorisedContractorCompanies.value = value
    }

    function setSeenHazards(value: boolean) {
      seenHazards.value = value
    }

    function setSite(site: Site): void {
      exists.value = !!site
      name.value = site.name
      address.value = site.street
      id.value = parseInt(String(site.id))
      uuid.value = site.uuid
      longitude.value = site.locationCentroid[0]
      latitude.value = site.locationCentroid[1]
    }

    function setSiteHazards(hazards: SiteHazard[]): void {
      siteHazards.value = hazards
    }

    function setSiteDocuments(documents: VerifiedDocument[]): void {
      siteDocuments.value = documents
    }

    function setSelectionConfirmed(value: boolean): void {
      selectionConfirmed.value = value
    }

    function setHasSameSiteSetInSession(value: boolean): void {
      hasSameSiteSetInSession.value = value
    }

    function resetSite() {
      address.value = ''
      authorisedContractorCompanies.value = []
      exists.value = false
      id.value = 0
      latitude.value = 0
      longitude.value = 0
      name.value = ''
      selectionConfirmed.value = false
      hasSameSiteSetInSession.value = false
      siteDocuments.value = []
      siteHazards.value = []
      seenHazards.value = false
      uuid.value = ''
    }

    return {
      address,
      authorisedContractorCompanies,
      canContinueAsVisitor,
      exists,
      hasSameSiteSetInSession,
      id,
      isSiteStoreDefault,
      latitude,
      longitude,
      name,
      resetSite,
      seenHazards,
      selectionConfirmed,
      setAuthorisedContractorCompanies,
      setHasSameSiteSetInSession,
      setSeenHazards,
      setSelectionConfirmed,
      setSite,
      setSiteDocuments,
      setSiteHazards,
      siteDocuments,
      siteHazards,
      uuid,
    }
  },
  {
    persist: true,
  },
)
