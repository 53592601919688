import axios from 'axios'
import { Frequency } from '@/services/api/serviceFrequency'

export type Service = {
  canRepair: boolean
  frequencies: Frequency[]
  clientServiceId: number
  name: string
  parentServiceId: number
}

export type SiteServiceType = {
  frequencies: Frequency[]
  siteServiceId: number
  name: string | null
  siteUuid: number
  serviceId: number
  service: Service
  lookupSubService: {
    siteServiceId: number
    siteUuid: string
    serviceId: number
    subServiceId: number
  }
}

export const getSiteServices = async (
  siteUuid: string,
  contractorCompanyId: number,
): Promise<SiteServiceType[]> => {
  const response = await axios.get('/api/sites/' + siteUuid + '/services', {
    params: {
      contractorCompanyId: contractorCompanyId,
    },
  })

  return response.data.data
}

export const getAllServices = async (
  contractorCompanyId: number,
  customerId: number | null,
): Promise<Service[]> => {
  const response = await axios.get('/api/services', {
    params: {
      contractorCompanyId: contractorCompanyId,
      customerId: customerId,
    },
  })

  return response.data.data
}
