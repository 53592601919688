<template>
  <div class="h-80">
    <LMap v-model:zoom="zoom" :center="center" :use-global-leaflet="false">
      <LTileLayer
        :attribution="attribution"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></LTileLayer>
      <LMarker :lat-lng="center" :options="{ alt: 'Site Marker' }"></LMarker>
      <LMarker
        v-if="userPosition"
        :lat-lng="userPosition"
        name="You are here"
        :options="{ alt: 'User Marker' }"
      ></LMarker>
      <LPolyline
        v-if="userPosition"
        :lat-lngs="[center, userPosition]"
        color="black"
      ></LPolyline>
    </LMap>
  </div>
</template>

<script setup lang="ts">
  import 'leaflet/dist/leaflet.css'
  import {
    LMap,
    LMarker,
    LPolyline,
    LTileLayer,
  } from '@vue-leaflet/vue-leaflet'
  import { PropType, ref } from 'vue'

  defineProps({
    center: {
      required: true,
      type: Object as PropType<{ lat: number; lng: number }>,
    },

    userPosition: {
      default: null,
      type: Object as PropType<{ lat: number; lng: number }>,
    },
  })
  const attribution =
    '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors'
  const zoom = ref(15)
</script>

<style lang="css" scoped>
  ::v-deep(img[alt='User Marker']) {
    /* Changes blue marker to red */
    filter: hue-rotate(145deg);
  }

  ::v-deep(.leaflet-pane) {
    z-index: 0 !important;
  }

  ::v-deep(.leaflet-control-container),
  ::v-deep(.leaflet-control-container > *),
  ::v-deep(.leaflet-control-container > * > *) {
    z-index: 1 !important;
  }
</style>
