<template>
  <div>
    <TitleBanner title="Select Site (Bypass)" />
    <div class="my-2 p-4">
      <BaseSearchBar
        data-test="siteSearch"
        placeholder="Search for site..."
        @search="searchQueryChanged"
      >
      </BaseSearchBar>
    </div>
    <div class="px-4">
      <div class="mt-6 flow-root">
        <ul class="-my-5 divide-y divide-gray-200">
          <li
            v-for="site in sites"
            :key="site.id"
            class="py-4"
            @click.once="selectSite(site)"
          >
            <SiteListItem
              :site-address="site.street"
              :site-name="site.name"
              :customer-logo="getCustomerLogo(site.customer)"
            />
          </li>
        </ul>
        <div v-if="loading" class="pt-3 text-center">
          <span class="mx-auto block h-12 w-12 animate-spin-slow">
            <img
              id="contentIcon"
              src="../assets/black-spinner-transparent.png"
              class="h-full w-full text-blue-500"
              alt="icon"
            />
          </span>
          <span>Loading...</span>
        </div>
        <div v-if="noMore" class="my-4 py-3 text-center">
          There are no more sites.
        </div>
        <div v-if="noResult" class="my-4 py-3 text-center">
          There are no available sites.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
  import TitleBanner from '@/components/TitleBanner.vue'
  import {
    controller as abortController,
    Customer,
    getSites,
    Site,
  } from '@/services/api/site'
  import { useRouter } from 'vue-router'
  import BaseSearchBar from '@/components/BaseSearchBar.vue'
  import useScroll from '@/components/use/scroll'
  import SiteListItem from '@/components/SiteListItem.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import { debounce } from '@/utilities/debounce'
  import customerLogoMap from '@/utilities/customerLogo'
  import { useCustomerStore } from '@/storage/customer'
  import { storeToRefs } from 'pinia'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useUserPositionStore } from '@/storage/userPosition'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'

  export default defineComponent({
    name: 'SelectBypassSite',
    components: {
      BaseSearchBar,
      SiteListItem,
      TitleBanner,
    },

    async setup() {
      const router = useRouter()
      const { name: siteName } = storeToRefs(useSiteStore())
      const { setSite, setSelectionConfirmed } = useSiteStore()
      const {
        id: userId,
        isUserStoreDefault,
        activeVendorBrandIds,
      } = storeToRefs(useUserStore())
      const { position } = storeToRefs(useUserPositionStore())
      const { setCustomerInfo } = useCustomerStore()
      const { addScrollListener, removeScrollListener } =
        useScroll(loadMoreSites)
      const { fingerprint } = storeToRefs(useFingerprintStore())
      const sites = ref<Site[]>([])
      const searchQuery = ref<string | null>(null)
      const loading = ref(false)
      const noMore = ref(false)
      const noResult = computed(() => {
        return sites.value.length === 0 && !loading.value
      })
      let page = 1

      onMounted(() => {
        setSelectionConfirmed(false)
        addScrollListener()
      })

      onUnmounted(() => {
        removeScrollListener()
      })

      await loadSites(page)

      async function loadSites(page: number): Promise<void> {
        loading.value = true
        noMore.value = false

        if (isUserStoreDefault.value) {
          throw new InvalidStoreException(
            {
              fingerprint: fingerprint.value,
              userId: userId.value,
              userLocation: position.value,
            },
            ['InvalidStore', 'FetchBypassSites'],
          )
        }

        const response = await getSites(
          userId.value.toString(),
          position.value,
          page.toString(),
          searchQuery.value,
          activeVendorBrandIds.value,
        )

        if (response) {
          if (response.length > 0) {
            sites.value.push(...response)
          }
          manageLoadingState(response)
        }
      }

      function manageLoadingState(data: Site[]) {
        if (data.length === 0 && sites.value.length !== 0) {
          noMore.value = true
        }
        loading.value = false
      }

      async function selectSite(site: Site): Promise<void> {
        setSite(site)
        setCustomerInfo(site.customer)

        await router.replace({ name: 'confirmBypassSite' })
      }

      const querySites = debounce(() => loadSites(page), 500)

      async function searchQueryChanged(search: string): Promise<void> {
        abortController.abort()
        loading.value = true
        page = 1
        searchQuery.value = search ? search : null
        sites.value = []
        querySites()
      }

      async function loadMoreSites(): Promise<void> {
        if (!loading.value && !noMore.value && !noResult.value) {
          page++
          await loadSites(page)
        }
      }

      function getCustomerLogo(customer: Customer): string {
        return (
          customerLogoMap.get(customer.name ?? '') ??
          (customerLogoMap.get('default') as string)
        )
      }

      return {
        getCustomerLogo,
        loading,
        noMore,
        noResult,
        searchQueryChanged,
        selectSite,
        siteName,
        sites,
      }
    },
  })
</script>
