<template>
  <div>
    <SiteBanner :site-address="siteAddress" :site-name="siteName" />
    <div class="p-4">
      <h1 class="mb-2 p-2 text-center text-lg">Site Selected</h1>
      <div class="mt-2 w-full">
        <MapWithPin
          :center="center"
          :user-position="{ lat: position.latitude, lng: position.longitude }"
        />
      </div>
      <p class="my-2 pt-1 text-center text-lg">Is this the correct site?</p>
      <div class="flex space-x-4 pt-3">
        <SecondaryButton
          type="button"
          action="NO"
          identifier="NoBtn"
          @click.once="backToSiteList"
        />
        <PrimaryButton
          type="button"
          action="YES"
          identifier="YesBtn"
          @click.once="confirmSite"
        />
      </div>
      <LoadingView :is-loading="isLoading" message="Retrieving companies..." />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import PrimaryButton from '@/components/PrimaryButton.vue'
  import MapWithPin from '@/components/MapWithPin.vue'
  import { useRouter } from 'vue-router'
  import LoadingView from '@/views/LoadingView.vue'
  import { useNativeScanStore } from '@/storage/nativeScan'
  import SiteBanner from '@/components/SiteBanner.vue'
  import InvalidStoreException from '@/exceptions/InvalidStoreException'
  import SecondaryButton from '@/components/SecondaryButton.vue'
  import { useUserLogin } from '@/storage/userLogin'
  import { storeToRefs } from 'pinia'
  import { useBypassStore } from '@/storage/bypass'
  import { useCustomerStore } from '@/storage/customer'
  import { useFingerprintStore } from '@/storage/fingerprint'
  import { useSiteStore } from '@/storage/site'
  import { useUserStore } from '@/storage/user'
  import { useUserPositionStore } from '@/storage/userPosition'

  const { setAuthorisedContractorCompanies, setSelectionConfirmed } =
    useSiteStore()
  const {
    id: siteId,
    address: siteAddress,
    name: siteName,
    latitude: siteLatitude,
    longitude: siteLongitude,
    isSiteStoreDefault,
  } = storeToRefs(useSiteStore())
  const { hasVisitorManagement, id: brandId } = storeToRefs(useCustomerStore())
  const router = useRouter()
  const { id: userId, isUserStoreDefault } = storeToRefs(useUserStore())
  const { activeVendorsForBrand } = useUserStore()
  const { setScanBypassed } = useBypassStore()
  const { isVisitor } = storeToRefs(useUserLogin())
  const { fingerprint } = storeToRefs(useFingerprintStore())
  const isLoading = ref(false)
  const { position } = storeToRefs(useUserPositionStore())

  async function backToSiteList(): Promise<void> {
    setSelectionConfirmed(false)
    await router.replace({ name: 'selectNearbySite' })
  }

  const center = {
    lat: siteLatitude.value,
    lng: siteLongitude.value,
  }

  async function confirmSite(): Promise<void> {
    if (isVisitor.value) {
      setSelectionConfirmed(true)
      await router.replace({ name: 'representation' })
      return
    }

    if (isSiteStoreDefault.value || isUserStoreDefault.value) {
      throw new InvalidStoreException(
        {
          fingerprint: fingerprint.value,
          siteId: siteId.value,
          userId: userId.value,
        },
        ['InvalidStore', 'FetchAuthorisedContractorCompanies'],
      )
    }

    if (
      activeVendorsForBrand(brandId.value).length > 0 ||
      hasVisitorManagement.value
    ) {
      setScanBypassed(false)
      setSelectionConfirmed(true)
      setAuthorisedContractorCompanies(activeVendorsForBrand(brandId.value))
      await router.replace({ name: 'contractorCompanySelection' })
    }
  }

  // allow skipping this confirmation if native scanned QR code
  if (useNativeScanStore().uuid?.length === 36) {
    await confirmSite()
  }
</script>
